<template>
  <div class="contaner-warehouse-order">
    <CRow>
      <h4 class="font-size-16 font-weight-bold mb-3 mt-2">
        Потребность в материалах
      </h4>
    </CRow>

    <CRow>
      <CButton @click="collapse = !collapse" color="dark" class="mb-4">
        Добавить запчасть
      </CButton>
    </CRow>
    <CCollapse :show="collapse">
      <Form class="border pl-15">
        <CRow class="mb-30 mt-40">
          <v-select
            class="v-select"
            style="width: 80%"
            label="code"
            :options="
              equipmentDetails.filter((item) => item.virtual && !item.archive)
            "
            v-model="equipmentDetail"
            placeholder="Запчасти"
            :getOptionKey="(item) => item.code"
            :clearSearchOnSelect="true"
            :closeOnSelect="true"
          >
            <template
              class="w-100"
              #option="{ code, description, model_preview }"
            >
              <div class="list-item-container-row">
                <h6 class="grid-h6-row pt-3 green">
                  {{ code }}
                </h6>
                <p class="grid-p-row">
                  {{ description }}
                </p>
                <img class="grid-image-row" :src="model_preview" />
              </div>
            </template>
          </v-select>
        </CRow>

        <CRow class="mb-20 mt-20">
          <CInput
            v-model="equipmentDetailAmount"
            type="number"
            placeholder="Количество"
          />
        </CRow>
        <CRow class="mb-40 mt-20">
          <CButton
            @click="addEquipmentDetailToEquipmentPart"
            color="success"
            type="button"
            >Добавить
          </CButton>
        </CRow>
      </Form>
    </CCollapse>

    <CRow class="mb-4">
      <table class="table">
        <thead class="text-13">
          <th>№</th>
          <th style="width: 20%">Наименование</th>
          <th>Код</th>
          <th class="text-right" style="width: 10%">Кол-во</th>
          <th class="text-right">Цена</th>
          <th class="text-right">Стоимость</th>
          <th class="text-right">ТС</th>
          <th class="text-right">Вес, кг</th>
          <th class="text-right">Вес общий</th>
        </thead>

        <tbody >
          <tr class="text-12"
            v-for="(equipmentDetail, index) in equipmentTotalDetails"
            :key="equipmentDetail.id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ equipmentDetail.description }}</td>
            <td>{{ equipmentDetail.code }}</td>

            <td class="text-right">
              <div class="text-right text-12" v-if="equipmentDetail.virtual">
                <CInput class="text-12"
                  v-model="equipmentDetail.amount"
                  @input="
                    requestEquipmentDetailEquipmentPartUpdate(equipmentDetail)
                  "
                />
              </div>
              <div class="text-12" v-else>
                {{ equipmentDetail.amount }}
              </div>
            </td>
            <td class="text-right">
              {{
                (
                  Math.round(
                    (parseFloat(equipmentDetail.price) + Number.EPSILON) * 100
                  ) / 100
                )
                  .toString()
                  .replace(".", ",")
              }}
            </td>
            <td class="text-right">
              {{
                (
                  Math.round((equipmentDetail.cost + Number.EPSILON) * 100) /
                  100
                )
                  .toString()
                  .replace(".", ",")
              }}
            </td>
            <td class="text-right" v-if="equipmentDetail.virtual">
              <CButton
                color="light"
                size="sm"
                @click="
                  requestEquipmentDetailEquipmentPartDelete(equipmentDetail)
                "
              >
                <CIcon name="cil-trash" />
              </CButton>
            </td>
            <td v-else></td>
            <td class="text-right">{{ equipmentDetail.weight }}</td>
            <td class="text-right">{{ equipmentDetail.weightAmount }}</td>
          </tr>
          <tr class="text-13">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right">Итого</td>
            <td class="text-right">
                {{
                    (Math.round((totalCost + Number.EPSILON) * 100) / 100)
                    .toString()
                    .replace(".", ",")
                }}
            <td></td>
            <td></td>
            <td class="text-right">
                {{
                    (Math.round((totalWeight + Number.EPSILON) * 100) / 100)
                    .toString()
                    .replace(".", ",")
                }}
            </td>
            </td>
          </tr>
        </tbody>
      </table>
    </CRow>
    <CRow>
      <small
        >* — запчасти, которые нельзя редактировать, удалять и добавлять в
        данных выше, приходят из разбора 3D моделей. Если есть неточности в этих
        данных, то нужно проверить корректность отрисовки запчастей в моделе, и
        при необходимости перерисовать 3D модель.</small
      >
    </CRow>
  </div>
</template>


<script>
import { moneyFormat } from "../utils.js";
import { mapActions } from "vuex";
import { debounce } from "lodash";
import vSelect from "vue-select";

export default {
  name: "EquipmentPartDetails",
  props: {
    equipmentPart: Object,
    equipmentDetails: Array,
    equipmentPackages: Array,
  },
  emits: ["equipment-part-equipment-detail-changed"],
  data() {
    return {
      totalCost: 0,
      totalWeight: 0,
      visible: false,
      collapse: false,
      equipmentDetail: null,
      equipmentDetailAmount: null,
      unsubscribe: null,
    };
  },
  components: { vSelect },
  computed: {
    equipmentTotalDetails() {
      this.totalCost = 0;
      this.totalWeight = 0;
      const result = [];
      for (const equipmentDetail of this.equipmentDetails) {
        const currentDetailAmount =
          this.equipmentPartHasDetail(equipmentDetail);
        if (currentDetailAmount > 0) {
          result.push({
            id: equipmentDetail.id,
            code: equipmentDetail.code,
            description: equipmentDetail.description,
            amount: currentDetailAmount,
            price: equipmentDetail.price,
            weight: equipmentDetail.weight,
            weightAmount: equipmentDetail.weight * currentDetailAmount,
            cost: equipmentDetail.price * currentDetailAmount,
            virtual: equipmentDetail.virtual,
          });
          this.totalCost += equipmentDetail.price * currentDetailAmount;
          this.totalWeight += equipmentDetail.weight * currentDetailAmount;
        }
      }
      return result;
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      console.log(mutation);
      if (
        mutation.type ===
        "equipmentParts/createEquipmentDetailEquipmentPartSuccess"
      ) {
        this.$emit("equipment-part-equipment-detail-changed");
      }
    });
  },
  methods: {
    ...mapActions([
      "equipmentParts/createEquipmentDetailEquipmentPart",
      "equipmentParts/updateEquipmentDetailEquipmentPart",
      "equipmentParts/deleteEquipmentDetailEquipmentPart",
    ]),
    moneyFormat(value) {
      return moneyFormat(value);
    },
    equipmentPartHasDetail(equipmentDetail) {
      for (const testEquipmentDetail of this.equipmentPart.equipment_details) {
        if (equipmentDetail.id === testEquipmentDetail.equipment_detail_id) {
          return testEquipmentDetail.amount;
        }
      }
      return 0;
    },
    prepareSubmitData(equipmentDetail) {
      console.log(JSON.stringify(equipmentDetail, null, 4));
      return {
        equipment_detail_id: equipmentDetail.id,
        equipment_part_id: this.equipmentPart.id,
        amount: equipmentDetail.amount,
      };
    },

    createEquipmentDetailEquipmentPart(equipmentDetail) {
      const formData = this.prepareSubmitData(equipmentDetail);
      console.log("formData " + JSON.stringify(formData, null, 4));
      this["equipmentParts/createEquipmentDetailEquipmentPart"](formData);
    },
    requestEquipmentDetailEquipmentPartUpdate: debounce(function (
      equipmentDetail
    ) {
      this.updateEquipmentDetailEquipmentPart(equipmentDetail);
    },
    1000),

    updateEquipmentDetailEquipmentPart(equipmentDetail) {
      const formData = this.prepareSubmitData(equipmentDetail);
      this["equipmentParts/updateEquipmentDetailEquipmentPart"](formData);
    },

    requestEquipmentDetailEquipmentPartDelete: debounce(function (
      equipmentDetail
    ) {
      this.deleteEquipmentDetailEquipmentPart(equipmentDetail);
      this.$emit("equipment-part-equipment-detail-changed");
    },
    1000),

    deleteEquipmentDetailEquipmentPart(equipmentDetail) {
      const formData = this.prepareSubmitData(equipmentDetail);
      this["equipmentParts/deleteEquipmentDetailEquipmentPart"](formData);
    },

    addEquipmentDetailToEquipmentPart() {
      this.createEquipmentDetailEquipmentPart({
        id: this.equipmentDetail.id,
        amount: this.equipmentDetailAmount,
      });
      this.equipmentDetail = null;
      this.equipmentDetailAmount = null;
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #ccc;
}
</style>
