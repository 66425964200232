<template>
  <div class="template-list">
    <CRow>
      <CCol sm="4">
        <CCard>
          <CCardBody>
            <CTabs>
              <CTab
                title="Свойства"
                active
                v-if="equipmentPartMeta.model && equipmentPart"
              >
                <CRow class="mt-20">
                  <CCol>
                    <label>Архивный</label>
                  </CCol>
                  <CCol>
                    <input
                      type="checkbox"
                      v-model="equipmentPart.archive"
                      @input="updateModelArchive"
                    />
                  </CCol>
                </CRow>

                <CRow class="mt-40">
                  <CCol sm="12">
                    <CInput
                      label="Смещение по x"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentPartMeta.model.position.x"
                      @input="updateModelPosition"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Смещение по y"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentPartMeta.model.position.y"
                      @input="updateModelPosition"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Смещение по z"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentPartMeta.model.position.z"
                      @input="updateModelPosition"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Поворот по x"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentPartMeta.model.rotation.x"
                      @input="updateModelRotation"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Поворот по y"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentPartMeta.model.rotation.y"
                      @input="updateModelRotation"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Поворот по z"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentPartMeta.model.rotation.z"
                      @input="updateModelRotation"
                    />
                  </CCol>
                </CRow>
                <CRow class="mt-20">
                  <CCol>
                    <label>Зафиксировать 3D вид детали</label>
                  </CCol>
                  <CCol>
                    <input
                      type="checkbox"
                      v-model="equipmentPart.locked"
                      @input="updateModelLocked"
                    />
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="8">
        <CTabs>
          <CTab title="Деталь" active>
            <div id="canvas" style="height: 800px"></div>
          </CTab>
          <CTab title="Требование на склад" v-if="equipmentPart && equipmentDetails">
            <EquipmentPartDetails
              :equipmentPart="equipmentPart"
              :equipmentDetails="equipmentDetails"
              @equipment-part-equipment-detail-changed="updateEquipmentParts"
            >
            </EquipmentPartDetails>
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

import EquipmentPartDetails from "../../components/EquipmentPartDetails.vue";

export default {
  name: "EquipmentPart",
  data() {
    return {
      el: null,
      renderer: null,
      scene: null,

      lights: {
        ambient: null,
        directional: null,
      },
      cameras: {
        perspective: null,
      },
      controls: {
        perspective: null,
      },
      gltfLoader: null,
      geometries: {},
      materials: {},
      palette: {},
      equipmentPart: null,
      equipmentDetails: null,
      equipmentPartMeta: {
        model: null,
      },
      equipmentPartMeshes: [],
      animationFrameId: null,
    };
  },
  components: {
    EquipmentPartDetails,
  },
  computed: {
    ...mapState({
      equipmentParts: (state) => state.equipmentParts.all,
    }),
  },
  created() {
    this["equipmentParts/getAll"]();
  },
  mounted() {
    this.init();
    this.prepare();
    this.animate();
  },
  destroyed() {
    cancelAnimationFrame(this.animationFrameId);
  },
  methods: {
    ...mapActions([
      "equipmentParts/getAll",
      "equipmentDetails/getAll",
      "equipmentParts/update",
    ]),
    prepare: function () {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === "equipmentParts/getAllSuccess") {
          for (const equipmentPart of mutation.payload) {
            console.log(this.$route.params.code, this.$route.params.version);
            if (
              equipmentPart.code === this.$route.params.code &&
              equipmentPart.version === parseInt(this.$route.params.version)
            ) {
              this.equipmentPart = equipmentPart;
              this.gltfLoader.load(equipmentPart.model_constructor, (gltf) => {
                const scene = gltf.scene || gltf.scenes[0];
                this.processEquipmentPartModel(equipmentPart, scene);
              });
              break;
            }
          }
          this["equipmentDetails/getAll"]();
        }
        if (mutation.type === "equipmentDetails/getAllSuccess") {
          this.equipmentDetails = mutation.payload;
        }
      });
    },

    initCanvas: function () {
      this.el = document.getElementById("canvas");
      this.viewportWidth = this.el.clientWidth;
      this.viewportHeight = this.el.clientHeight;
    },

    initRenderer: function () {
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      //this.renderer.physicallyCorrectLights = true;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setClearColor(0xffffff);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.viewportWidth, this.viewportHeight);
      this.el.appendChild(this.renderer.domElement);
    },

    initScene: function () {
      this.scene = new THREE.Scene();
    },

    initGeometries: function () {
      this.geometries.whiteDot = new THREE.SphereGeometry(0.1, 10, 10);
      this.geometries.platform = new THREE.PlaneGeometry(10, 10);
    },

    initPalette: function () {
      this.palette.whiteDot = new THREE.Color(0xffffff);
      this.palette.platform = new THREE.Color(0x3fb27f);
    },

    initMaterials: function () {
      this.materials.whiteDot = new THREE.MeshBasicMaterial({
        color: this.palette.whiteDot,
      });
      this.materials.platform = new THREE.MeshStandardMaterial({
        color: this.palette.platform,
        side: THREE.DoubleSide,
      });
    },

    initLights: function () {
      this.lights.ambient = new THREE.AmbientLight(0xffffff, 1);
      this.scene.add(this.lights.ambient);

      this.lights.directional = new THREE.DirectionalLight(0xffffff, 4, 100);
      this.lights.directional.position.set(5, 8, 5);
    },

    initPlatform: function () {
      this.platform = new THREE.Mesh(
        this.geometries.platform,
        this.materials.platform,
        true
      );
      this.platform.rotation.x = Math.PI / 2;
      this.scene.add(this.platform);
    },

    initSceneCenter() {
      // this.sceneCenter = new THREE.Mesh(
      //   this.geometries.whiteDot,
      //   this.materials.v
      // );
      // this.scene.add(this.sceneCenter);
      this.scene.add(new THREE.AxesHelper(100));
    },

    initModelLoader: function () {
      THREE.ImageUtils.crossOrigin = "";
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function () {};

      loadingManager.onError = function () {};

      this.gltfLoader = new GLTFLoader(loadingManager);
    },

    initPerspectiveCamera: function () {
      const width = this.viewportWidth;
      const height = this.viewportHeight;
      this.cameras.perspective = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.001,
        10000
      );
      this.cameras.perspective.position.y = 5;
      this.cameras.perspective.position.z = 11;
      this.cameras.perspective.updateProjectionMatrix();
    },

    initPerspectiveControls: function () {
      this.controls.perspective = new OrbitControls(
        this.cameras.perspective,
        this.renderer.domElement
      );
      this.controls.perspective.enabled = true;
      this.controls.perspective.update();
    },

    init: function () {
      this.initCanvas();
      this.initRenderer();
      this.initScene();
      this.initLights();
      this.initModelLoader();
      this.initPerspectiveCamera();
      this.initPerspectiveControls();
      this.initGeometries();
      this.initPalette();
      this.initMaterials();
      this.initPlatform();
      this.initSceneCenter();
    },

    addEquipmentPartToScene: function (equipmentPart, object) {
      const equipmentPartToAdd = {
        item: equipmentPart,
        model: object,
      };
      equipmentPartToAdd.model.position.x = equipmentPart.scene_position_x;
      equipmentPartToAdd.model.position.y = equipmentPart.scene_position_y;
      equipmentPartToAdd.model.position.z = equipmentPart.scene_position_z;
      equipmentPartToAdd.model.rotation.x = equipmentPart.scene_rotation_x;
      equipmentPartToAdd.model.rotation.y = equipmentPart.scene_rotation_y;
      equipmentPartToAdd.model.rotation.z = equipmentPart.scene_rotation_z;
      this.scene.add(equipmentPartToAdd.model);

      return equipmentPartToAdd;
    },

    processEquipmentPartModel(model, object) {
      this.equipmentPartMeta = this.addEquipmentPartToScene(model, object);
      for (const mesh of object.children) {
        this.equipmentPartMeshes.push(mesh);
      }
    },

    animate: function () {
      this.animationFrameId = requestAnimationFrame(this.animate);
      this.controls.perspective.update();
      this.renderer.render(this.scene, this.cameras.perspective);
    },

    updateModelPosition() {
      this.requestEquipmentPartUpdate();
    },

    updateModelRotation() {
      this.requestEquipmentPartUpdate();
    },

    updateModelArchive() {
      this.requestEquipmentPartUpdate();
    },

    updateModelLocked() {
      this.requestEquipmentPartUpdate();
    },

    requestEquipmentPartUpdate: debounce(function () {
      this.updateEquipmentPart();
    }, 1000),

    updateEquipmentPart() {
      this["equipmentParts/update"]({
        id: this.equipmentPart.id,
        archive: this.equipmentPart.archive,
        locked: this.equipmentPart.locked,
        scene_position_x: this.equipmentPartMeta.model.position.x,
        scene_position_y: this.equipmentPartMeta.model.position.y,
        scene_position_z: this.equipmentPartMeta.model.position.z,
        scene_rotation_x: this.equipmentPartMeta.model.rotation.x,
        scene_rotation_y: this.equipmentPartMeta.model.rotation.y,
        scene_rotation_z: this.equipmentPartMeta.model.rotation.z,
      });
    },
    updateEquipmentParts() {
      this["equipmentParts/getAll"]();
    },
  },
};
</script>

<style>
.mt-40 {
  margin-top: 40px;
}
</style>
